<template>
  <div id="wrapper">
    <sidebar></sidebar>
    <div id="content-wrapper">
      <div id="content">
        <topbar></topbar>
        <router-view></router-view>
        <bffooter></bffooter>
      </div>
    </div>
  </div>
</template>

<script>
import sidebar from '@/components/Sidebar'
import topbar from '@/components/Topbar'
import bffooter from '@/components/BfFooter'

export default {
  name: 'Home',
  components: {
    sidebar,
    topbar,
    bffooter
  },
  data () {
    return {
      loading: false,
      message: null,
      error: null
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
