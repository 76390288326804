<template>
  <ul class="navbar-nav bg-gradient-original sidebar sidebar-dark accordion" id="accordionSidebar" ref="accordionSidebar" :class="{ toggled: isToggled }">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="/home/dashboard" @click.prevent="transition('/home/dashboard')">
      <div class="sidebar-brand-icon">
        <img src="../assets/tokai-logo.png" class="logo" alt="Rounded image">
      </div>
      <div class="sidebar-brand-text mx-2">東海大<br>PEER-AppStudy<br>管理</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item">
      <a class="nav-link" href="/home/dashboard" @click.prevent="transition('/home/dashboard')">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span>
      </a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <div class="sidebar-heading">
      進行管理
    </div>

    <li class="nav-item">
      <a class="nav-link" href="/home/users" @click.prevent="transition('/home/users')">
        <b-icon icon="list-check" class="mr-1"></b-icon>
        <span>同意リスト</span>
      </a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <div class="sidebar-heading">
      アンケート
    </div>

    <li class="nav-item">
      <a class="nav-link" href="/home/enquetes" @click.prevent="transition('/home/enquetes')">
        <b-icon icon="list-check" class="mr-1"></b-icon>
        <span>事後アンケート</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" href="/home/enquetes" @click.prevent="transition('/home/enquetes')">
        <b-icon icon="list-check" class="mr-1"></b-icon>
        <span>測定アンケート</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" href="/home/enquetes" @click.prevent="transition('/home/enquetes')">
        <b-icon icon="list-check" class="mr-1"></b-icon>
        <span>事後アンケート</span>
      </a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">
    <!-- Heading -->
    <div class="sidebar-heading">
      参加者管理
    </div>

    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item">
      <a class="nav-link" href="/home/cognito_admins" @click.prevent="transition('/home/cognito_admins')">
        <i class="fas fa-fw fa-id-card"></i>
        <span>管理者リスト</span>
      </a>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0 toggleSidebarBtn left" id="sidebarToggle" @click="toggleSidebar" v-if="!isToggled">
        <b-icon icon="chevron-left"></b-icon>
      </button>
      <button class="rounded-circle border-0 toggleSidebarBtn right" id="sidebarToggle" @click="toggleSidebar" v-else>
        <b-icon icon="chevron-right"></b-icon>
      </button>
    </div>
  </ul>
</template>

<script>
import { Logger } from 'aws-amplify'
const logger = new Logger('Sidebar')

export default {
  name: 'Sidebar',
  data () {
    return {
    }
  },
  computed: {
    isToggled () {
      return this.$store.getters.sidebarToggled
    }
  },
  methods: {
    toggleSidebar () {
      let params = { isToggled: !this.isToggled }
      this.$store.dispatch('toggleSidebar', params)
    },
    transition (path) {
      logger.debug(this.$route)
      if (this.$route.path !== path) {
        this.$router.push({ path: path })
      }
    }
  }
}
</script>
<style scoped>
.logo {
  width: 50px;
}
.bg-gradient-original {
  background-color: #00873C;
  background-image: linear-gradient(180deg, #00873C 30%,#60B080 100%);
  background-size: cover;
}
.sidebar-dark .nav-item .nav-link {
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
}
.toggleSidebarBtn {
  color: #fff;
}
.sidebar #sidebarToggle::after, .sidebar.toggled #sidebarToggle::after {
  content: '';
  margin-left: 0;
  margin-right: 0;
}
</style>
