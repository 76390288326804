<template>
  <b-navbar toggleable="md" variant="light" type="light" class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <!-- Sidebar Toggle (Topbar) -->
    <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" @click="toggleSidebar">
      <i class="fa fa-bars"></i>
    </button>

    <!-- Topbar Navbar -->
    <b-navbar-nav class="ml-auto">

      <div class="topbar-divider d-none d-sm-block"></div>

      <!-- Nav Item - User Information -->
      <b-nav-item-dropdown right class="dropdown no-arrow">
        <!-- Using 'button-content' slot -->
        <template slot="button-content">
          <span class="mr-2 d-lg-inline text-gray-600 small">{{username}}</span>
          <i class="fas fa-user"></i>
        </template>
        <b-dropdown-item href="/signout">
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            サインアウト
        </b-dropdown-item>
      </b-nav-item-dropdown>

    </b-navbar-nav>

  </b-navbar>
</template>

<script>
export default {
  name: 'topbar',
  data () {
    return {
    }
  },
  computed: {
    isToggled () {
      return this.$store.getters.sidebarToggled
    },
    username () {
      const user = this.$store.state.user
      return user && (user.username || user.attributes['username'])
    }
  },
  methods: {
    toggleSidebar () {
      let params = { isToggled: !this.isToggled }
      this.$store.dispatch('toggleSidebar', params)
    }
  }
}
</script>
