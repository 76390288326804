<template>
  <div class="main-footer mt-5">
    <hr class="divider" />
    <div class="my-3">
      <div class="text-center font-weight-light small">
      &copy; 東海大学大学院医学研究科 ライフケアセンター
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bffooter'
}
</script>

<style scoped>
</style>
